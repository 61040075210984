<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Alejandro Orozco Marulanda                                    ###### -->
<!-- ###### @date: Agosto 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="contenido">

    <!--Snackbar de elemento exitoso-->
    <v-snackbar location="top" v-model="alertValue" :color="alertResultado ? 'success' : 'error'" timeout="2000" top>
      {{ alertResultado ? "Registro creado con exito" : "No Creado" }}
    </v-snackbar>

    <!--Snackbar de error-->
    <v-snackbar v-model="snackbar" top color="error" timeout="2000">
      {{ textoSnackbar }}
    </v-snackbar>
    <!-- Filtros para buscar item -->
    <section class="d-flex">
      <!-- Filtrar por el Documento de un item -->
      <v-text-field type="number" :style="{ maxWidth: '30%' }" class="me-2 filtro" v-model="buscarDocumento"
        label="Documento" outlined dense hide-details color="primary">
      </v-text-field>
      <!-- Filtrar por el nombre de un item -->
      <v-text-field class="me-2 filtro" v-model="buscarNombre" label="Nombre" outlined dense
        hide-details color="primary">
      </v-text-field>

      <!-- Este botón abre un diálogo para agregar un nuevo Item -->
      <div v-if="!sincEnterprise.some(x => x === enterprise.code)">
        <v-tooltip left color="success" :open-on-focus="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab small color="success" v-on="on" v-bind="attrs" 
              @mousedown.prevent="dialogoCrearItem = true">
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <span>Agregar</span>
        </v-tooltip>
      </div>
    </section>

    <!-- Diálogo para crear una Item -->
    <v-dialog v-model="dialogoCrearItem" transition="dialog-bottom-transition" max-width="40rem" persistent :editar="false">
      <v-card>
        <v-card-title class="encabezado">
          <span class="text-h6"> {{ tituloDialogo }} </span>
        </v-card-title>
        <v-card-text class="pt-5">
          <!-- Formulario para registrar una Item -->
          <v-form ref="itemForm">
            <div class="d-flex">
              <!-- Campo para el tipo de Documento -->
              <v-select class="campo me-2" v-model="crearItem.tipoIden" label="Tipo documento" dense outlined
                :rules="[rules.required]" :items="tiposIdentificacion" item-value="id" item-text="name" color="primary" :readonly="this.editar"
                :menu-props="{ offsetY: true, maxWidth: 292, maxHeight: 260 }" :disabled="editar">
              </v-select>
              <!-- Campo para el número de documento -->
              <v-text-field type="number" class="campo" v-model="crearItem.numeroDocumento" label="Documento" dense
                outlined :rules="[rules.required]" color="primary" :disabled="editar">
              </v-text-field>
            </div>
            <div class="d-flex">
              <!-- Campo para el nombre -->
              <v-text-field class="campo me-2" v-model="crearItem.nombre" label="Nombres" dense outlined
                :rules="[rules.required]" color="primary" :disabled="sincEnterprise.some(x => x === enterprise.code)">
              </v-text-field>
              <!-- Campo para apellidos -->
              <v-text-field class="campo" v-model="crearItem.apellidos" label="Apellidos" dense outlined
                :rules="[rules.required]" color="primary" :disabled="sincEnterprise.some(x => x === enterprise.code)">
              </v-text-field>
            </div>
            <div class="d-flex">
              <v-menu ref="menuFecha" v-model="menuFecha" :close-on-content-click="false"  transition="scale-transition"
                offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field class="me-2 campo" v-model="crearItem.fechaNacimiento" label="Fecha de nacimiento" readonly v-bind="attrs" v-on="on"
                    dense outlined :rules="[rules.required]" :disabled="sincEnterprise.some(x => x === enterprise.code)">
                  </v-text-field>
                </template>
                <v-date-picker v-model="crearItem.fechaNacimiento" @input="$refs.menuFecha.save(crearItem.fechaNacimiento)" 
                  no-title scrollable locale="es-co">
                </v-date-picker>
              </v-menu>
              <v-select class="campo" v-model="crearItem.genero" label="Género" dense outlined
                :rules="[rules.required]" :items="generos" color="primary" :menu-props="{ offsetY: true }"
                :disabled="sincEnterprise.some(x => x === enterprise.code)">
              </v-select>
            </div>
            <div class="d-flex">
              <v-text-field class="campo me-2" v-model="crearItem.email" outlined dense label="Correo" :rules="[rules.required]" 
                color="primary" @input="validacionCorreo" :error-messages="errorCorreo"  @keypress="formatoCorreo" @paste="validarPegado"
                :disabled="sincEnterprise.some(x => x === enterprise.code)">
              </v-text-field>
              <v-autocomplete class="campo" v-model="crearItem.usuario" label="Usuario" dense outlined @click="listarUsuarios()"
                :rules="[rules.required]" :items="usuarios" color="primary" :menu-props="{ maxWidth: 292, maxHeight: 200, offsetY: true }">
              </v-autocomplete>
            </div>
          </v-form>
          <!-- Botones del formulario -->
          <div class="d-flex justify-end">
            <v-btn class="me-2" text color="error" @click="clear()">Cerrar</v-btn>
            <v-btn :color="!formValid ? 'grey' : 'success'" :disabled="!formValid" depressed
              @click="!editar ? agregarItem() : editarItem()">Guardar</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Tabla de items -->
    <v-data-table class="elevation mt-4" :items="tabla.items" :loading="tabla.loading" fixed-header
      :headers="tabla.headers" :items-per-page-options="tabla.itemsPerPageOptions" 
      :items-per-page.sync="tabla.itemsPerPage" :footer-props="tabla.footerProps"
      :page.sync="tabla.page" :server-items-length="tabla.totalPage" height="64vh">
      <!-- Columna del tipo de identificacion -->
      <template v-slot:[`item.identificacion`]="{ item }">
        {{ obtenerTipoDocumento(item.tipoIden) }}
      </template>
      <!-- Columna del nombre -->
      <template v-slot:[`item.nombre`]="{ item }">
        {{ item.nombre }} {{ item.apellidos }}
      </template>
      <!-- Columna del estado -->
      <template v-slot:[`item.estado`]="{ item }">
        <v-icon :color="item.eliminado === false ? 'success' : 'error'">
          {{ item.eliminado === false ? 'check_circle' : 'cancel' }}
        </v-icon>
        {{ item.eliminado === false ? 'Activo' : 'Inactivo' }}
      </template>
      <!-- Columna acciones -->
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip bottom :color="item.eliminado === false ? 'orange' : 'grey'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn :color="item.eliminado === false ? 'orange' : 'grey'" icon v-on="on" v-bind="attrs" class="ms-2 me-2"
              @click="item.eliminado === false && abrirDialogoEditar(item)" text>
              <v-icon>border_color</v-icon>
            </v-btn>
          </template>
          <span>{{ item.eliminado === false ? 'Editar' : `Colaborador inactivo` }}</span>
        </v-tooltip>

        <v-tooltip v-if="!sincEnterprise.some(x => x === enterprise.code)" bottom :color="item.eliminado === false ? 'error' : 'success'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn :color="item.eliminado === false ? 'error' : 'success'" icon class="ms-2 me-2" 
              v-on="on" v-bind="attrs" @click="abrirDialogoEstado(item)" text>
              <v-icon>{{ item.eliminado === false ? 'person_add_disabled' : 'how_to_reg' }}</v-icon>
            </v-btn>
          </template>
          <span>{{ item.eliminado === false ? 'Inactivar' : 'Activar' }}</span>
        </v-tooltip>
      </template>
      <!-- Columna de los detalles -->
      <template v-slot:[`item.detalles`]="{ item }">
        <v-tooltip bottom color="blue">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" icon color="blue"
              @click="abrirDialogoDetalles(item)">
              <v-icon>library_add</v-icon>
            </v-btn>
          </template>
          <span>Ver detalles</span>
        </v-tooltip>
      </template>
      <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
      </template>
    </v-data-table>

    <!-- dialogo para cambiar el estado -->
    <v-dialog v-model="dialogoCambiarEstado" transition="dialog-bottom-transition" max-width="23rem" persistent>
      <v-card>
        <v-card-title class="encabezado">
          <span class="text-h6"> {{ estado }} </span>
        </v-card-title>
        <v-card-text class="pt-5">
          <div class="d-flex justify-end">
            <v-btn class="me-2" color="error" text @click="dialogoCambiarEstado = false">No</v-btn>
            <v-btn color="success" depressed @click="editarItem()">Si</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialogo para ver los detalles del colaborador seleccioando -->
    <v-dialog v-model="dialogoDetalles" transition="dialog-bottom-transition" max-width="25rem" persistent>
      <v-card>
        <v-card-title class="encabezado">
          <span class="text-h6">Detalles</span>
        </v-card-title>
        <v-card-text class="pt-6 detalles">
          <div v-if="colaboradorSeleccionado.correo !== null || colaboradorSeleccionado.correo !== ''">
            <span class="font-weight-black">Correo: </span>
            {{ colaboradorSeleccionado.email }}
          </div>
          <div v-if="colaboradorSeleccionado.genero !== null || colaboradorSeleccionado.genero !== ''">
            <span class="font-weight-black">Género: </span>
            {{ colaboradorSeleccionado.genero === 'F' ? 'Femenino' : 'Masculino' }}
          </div>
          <div v-if="colaboradorSeleccionado.fechaNacimiento !== null || colaboradorSeleccionado.fechaNacimiento !== ''">
            <span class="font-weight-black">Fecha de nacimiento: </span>{{ formatoFecha(colaboradorSeleccionado.fechaNacimiento) }}
          </div>
          <div class="d-flex justify-end">
            <v-btn color="error" text @click="dialogoDetalles = false">Cerrar</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>
<!-- #################################################################################### -->
<!-- ###### Sección de Script                                                      ###### -->
<!-- #################################################################################### -->
<script>
import { mapMutations, mapState } from "vuex";
import { Role } from "../../../../router/role";
import moment from 'moment';

export default {
  name: "tipos-contratos",
  components: {
  },
  data() {
    return {
      ruta: "msa-hr/api/colaborador",
      nombreItem: "Colaborador",
      nombreId: "idColaborador",
      buscarNombre: "",
      buscarDocumento: "",
      dialogoCrearItem: false,
      dialogoDetalles: false,
      crearItem: {
        tipoIden: null,
        numeroDocumento: "",
        nombre: "",
        apellidos: "",
        genero: null,
        fechaNacimiento: null,
        email: "",
        usuario: null,
      },
      lastmodifiedby: "",
      tabla: {
        loading: false,
        items: [],
        headers: [
          { text: "TIPO DOCUMENTO", width: "15%", sortable: false, align: "center", value: "identificacion" },
          { text: "DOCUMENTO", width: "10%", sortable: false, align: "center", value: "numeroDocumento" },
          { text: "NOMBRE", width: "30%", sortable: false, align: "left", value: "nombre" },
          { text: "USUARIO", width: "10%", sortable: false, align: "center", value: "usuario" },
          { text: "ESTADO", width: "10%", sortable: false, align: "center", value: "estado" },
          { text: "ACCIONES", width: "15%", sortable: false, align: "center", value: "acciones" },
          { text: "DETALLES", width: "10%", sortable: false, align: "center", value: "detalles" },
        ],
        footerProps: {
          'items-per-page-options': [10, 20, 30, 40],
          'items-per-page-text': 'Items por página:',
          'show-current-page': true,
          'show-first-last-page': true
        },
        itemsPerPage: 10,
        page: 1,
        totalPage: 0,
      },
      tituloDialogo: "Agregar",
      userRoles: {},
      roles: Role,
      rules: {
        required: (v) => !!v || "Este campo es requerido."
      },
      dialogoCambiarEstado: false,
      editar: false,
      alertValue: false,
      alertResultado: false,
      sincEnterprise: [1],
      tiposIdentificacion: [
        { id: "C", name: "CÉDULA CIUDADANÍA" },
        { id: "D", name: "CARNET DIPLOMATICO" },
        { id: "E", name: "CÉDULA EXTRANJERO" },
        { id: "M", name: "PERMISO ESPECIAL DE PERMANENCIA" },
        { id: "N", name: "NIT" },
        { id: "P", name: "PASAPORTE" },
        { id: "S", name: "SALVOCONDUCTO DE PERMANENCIA" },
        { id: "T", name: "TARJETA IDENTIDAD" },
        { id: "U", name: "SIN IDENTIFICACION DEL EXTERIOR" },
        { id: "Z", name: "PERMISO DE PROTECCIÓN TEMPORAL" },
      ],
      generos: [
        { text: 'Femenino', value: 'F' },
        { text: 'Masculino', value: 'M' }
      ],
      colaboradorSeleccionado: {},
      menuFecha: false,
      fecha: null,
      tempFecha: null,
      errorCorreo: '',
      usuarios: [],
      snackbar: false,
      textoSnackbar: '',
    };
  },
  created() {
    this.userRoles = this.auth.roles;
  },
  computed: {
    ...mapState(["auth", "notify", "busy", "enterprise"]),
    formValid() {
      const { nombre, tipoIden, numeroDocumento, apellidos, fechaNacimiento, genero, email, usuario  } = this.crearItem;
      return nombre && tipoIden && numeroDocumento && apellidos && fechaNacimiento && genero && email && usuario != null;
    },
    estado() {
      return !this.crearItem.eliminado
        ? `¿Desea activar el colaborador?`
        : `¿Desea inactivar el colaborador?`;
    },
    /**
     * Función computada que devuelve la fecha actual en formato ISO (YYYY-MM-DD)
     * para que en el v-date-picker no seleccione fechas posteriores a las del día actual. 
     */
    currentDate() {
      return new Date().toISOString().split('T')[0];
    },
  },
  watch: {
    // Escucha los cambios en la página actual de la tabla de items.
    // Si cambia, llama a la función para listar las items.
    "tabla.page": function () {
      this.listarItems();
    },
    // Escucha cambios en la cantidad de ítems por página en la tabla de items.
    // Si cambia, reinicia la página actual a 1 y lista los items.
    "tabla.itemsPerPage": function () {
      this.tabla.page = 1;
      this.listarItems();
    },
    // Escucha cambios en los filtros de búsqueda.
    // Si el criterio de búsqueda cambia, reinicia la página actual a 1.
    buscarNombre: function () {
      this.tabla.page = 1;
      this.listarItems();
    },
    buscarDocumento: function () {
      this.tabla.page = 1;
      this.listarItems();
    },
  },
  methods: {
    ...mapMutations([
      "updateAuth",
      "hideNotify",
      "showSuccess",
      "showBusy",
      "hideBusy",
    ]),
    formatoFecha(fecha){
      return moment(fecha).format('YYYY-MM-DD')
    },
    /**
     * Método que obtiene y lista los items de la base de datos utilizando criterios de paginación y filtrado.
     * La respuesta obtenida se utiliza para actualizar la tabla de items en el frontend,
     * asignando la lista de items a 'tabla.items' y la cantidad total de items a 'tabla.totalPage'.
     */
    listarItems() {
      this.tabla.loading = true;
      this.$http
        .get(`${this.ruta}/listar`, {
          params: {
            page: this.tabla.page - 1,
            size: this.tabla.itemsPerPage,
            numeroDocumento: `${this.buscarDocumento}`,
            nombreCompleto: `${this.buscarNombre.toLowerCase()}`,
            idEmpresa: this.enterprise.code,
          },
        })
        .then((response) => {
          this.tabla.items = response.data.content;
          this.tabla.totalPage = response.data.totalElements;
          this.tabla.loading = false;
        })
        .catch((error) => {
          this.handleError(error, 'Error al listar los colaboradores');
          this.tabla.loading = false;
        });
    },
    //Función para editar un Ítem
    editarItem() {
      const item = {
        [this.nombreId]: this.crearItem[this.nombreId],
        numeroDocumento: this.crearItem.numeroDocumento,
        empresa: { idEmpresa: this.enterprise.code },
        nombre: this.crearItem.nombre.toUpperCase(),
        apellidos: this.crearItem.apellidos.toUpperCase(),
        fechaNacimiento: this.crearItem.fechaNacimiento ? `${this.crearItem.fechaNacimiento}T00:00:00-05:00` : null,
        genero: this.crearItem.genero,
        email: this.crearItem.email,
        tipoIden: this.crearItem.tipoIden.toUpperCase(),
        eliminado: this.crearItem.eliminado,
        usuario: this.crearItem.usuario,
        lastmodifiedby: this.usuario,
      }
      this.$http.post(`${this.ruta}/guardar`, item)
        .then(() => {
          this.listarItems();
          this.dialogoCrearItem = false;
          this.clear();
        }).catch((error) => {
          this.handleError(error, 'Error al editar el registro');
        });
    },
    //Función para agregar un Item
    async agregarItem() {

      const item = {
        tipoIden: this.crearItem.tipoIden.toUpperCase(),
        numeroDocumento: this.crearItem.numeroDocumento,
        nombre: this.crearItem.nombre.toUpperCase(),
        apellidos: this.crearItem.apellidos.toUpperCase(),
        fechaNacimiento: this.crearItem.fechaNacimiento ? `${this.crearItem.fechaNacimiento}T00:00:00-05:00` : null,
        genero: this.crearItem.genero,
        email: this.crearItem.email,
        empresa: { idEmpresa: this.enterprise.code },        
        eliminado: this.crearItem.eliminado,
        usuario: this.crearItem.usuario,
        lastmodifiedby: this.usuario,
      };

      this.$http
        .post(`${this.ruta}/guardar`, item)
        .then(() => {
          this.listarItems();
          this.dialogoCrearItem = false;
          this.clear();
          this.alertResultado = true;
          this.alertValue = true;
        })
        .catch((error) => {
          this.handleError(error, 'Error al crear un registro');
        });
    },
    /**
     * Abre el diálogo de editar el colaborador con los datos del objeto seleccionado(datos actuales del colaborador)
     * y la configuración necesaria para editar el colaborador.
     * @param {*} item - Objeto que contiene los datos del colaborador a editar.
     */
    abrirDialogoEditar(item) {
      this.tituloDialogo = "Editar";
      this.editar = true;
      this.crearItem = {
        ...item,
      };
      this.crearItem.fechaNacimiento = this.formatDateToYMD(item.fechaNacimiento);
      this.crearItem.usuario = this.crearItem.usuario === "NULL" ? null : this.crearItem.usuario;
      this.dialogoCrearItem = true;
    },
    /**
     * Abre el diálogo para camboiar el estado del colaborador
     * @param {*} item - Objeto que contiene los datos del colaborador.
     */
    abrirDialogoEstado(item) {
      this.crearItem = {
        ...item,
        eliminado: item.eliminado ? false : true,
      };
      this.dialogoCambiarEstado = true;
    },
    /**
     * Método que formatea la fecha seleccionada en el formulario.
     * @param date - fecha a formatear
     */
     formatDateToYMD(date) {
      if (!date) return '';
      const d = new Date(date);
      return d.toISOString().split('T')[0];
    },
    /**
     * Método que valida el correo ingresado .
     */
     validacionCorreo() {
      const correo = this.crearItem.email;
      const regex = /^(([^<>()[\],;:\s@"]+(\.[^<>()[\],;:\s@"]+)*)|(".+"))@(([^<>()[\],;:\s@"]+\.)+[^<>()[\],;:\s@"]{2,})$/i;
      if (correo) {
        if (!correo.match(regex)) {
          this.errorCorreo = 'Correo inválido.';
        } else {
          this.errorCorreo = '';
        }
      } else {
        this.errorCorreo = '';
      }
    },
    /**
     * Esta función se utiliza para validar que solo se ingresen los caracteres necesarios para el email
     * @param event - El evento del teclado que se produce al escribir en el campo de entrada
     */
    formatoCorreo(event) {
      const key = event.key;
      const regex = /^[a-zA-Z0-9@._-]$/;

      if (!key.match(regex)) {
        event.preventDefault();
      }
    },
    /**
     * Método para restringir el pegado en el campo de entrada
     * @param event 
     */
    validarPegado(event) {
      const contenidoPegado = (event.clipboardData || window.clipboardData).getData('text');
      const regex = /^[a-zA-Z0-9@._-]*$/;

      if (!contenidoPegado.match(regex)) {
        event.preventDefault();
      }
    },
    abrirDialogoDetalles(item) {
      this.colaboradorSeleccionado = item;
      this.dialogoDetalles = true;
    },
    /**
     * Método que busca el tipo de docuemnto a un valor en un arreglo.
     * @param item - El valor del tipo de identifiacion que se desea buscar en el arreglo.
     */
    obtenerTipoDocumento(item) {
      const tipo = this.tiposIdentificacion.find(tipoIden => tipoIden.id === item);
      return tipo ? tipo.name : '';
    },
    listarUsuarios() {
      this.$http.get(`msa-administration/api/usuario-empresa/empresa/${this.enterprise.code}`)
      .then(({ data }) => {
        data.forEach(usuario => { 
          this.usuarios.push({ text: `${usuario.usuarios.usuario} - ${usuario.usuarios.nombre}`, value: usuario.usuarios.usuario.trim().toLowerCase() }) 
        });
      })
      .catch((err) => {
        this.handleError(err, 'Error al listar los usuarios');
      });
    },
  /**
   * Método para mostrar las alertas cuando genera error el back.
   * @param error - El error generado.
   * @param mensaje - El mensaje de error.
   */
    handleError(error, mensaje) {
      console.log(error);
      this.snackbar = true;
      this.textoSnackbar = `${mensaje}: ${error.response?.data?.message || error.message || error}`;
    },
    /**
     * Método que se encarga de limpiar o restablecer varios valores a su estado inicial.
     */
    clear() {
      this.dialogoCrearItem = false;
      this.botonDisabled = false;
      this.tituloDialogo = "Agregar";
      this.errorCorreo = '';
      this.dialogoCambiarEstado = false;
      if (this.$refs.itemForm) {
        this.$refs.itemForm.reset();
      }
    },
  },
  mounted() {
    this.listarItems();
    this.lastmodifiedby = this.auth.username.toUpperCase();
  },
};
</script>
<!-- #################################################################################### -->
<!-- ###### Sección de Style                                                       ###### -->
<!-- #################################################################################### -->
<style scoped>
.contenido {
  padding: 1rem;
  height: 86vh;
}

.encabezado {
  background-color: #1867c0;
  color: white;
}

::v-deep .elevation div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .elevation div table thead tr th span {
  font-weight: bold;
  color: black !important;
}

.campo {
  width: 50%;
}

.detalles div , .detalles span {
  color: black;
}
</style>